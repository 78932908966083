@import-normalize;
/* bring in normalize.css styles */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
}

@font-face {
  font-family: "Arial";
  src: local("Arial"), url(./fonts/Arial.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* @font-face {
  font-family: 'Arial';
  src: local('Arial'), url(./fonts/Arial-narrow.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Arial";
  src: local("Arial"), url(./fonts/Arial-bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(./fonts/Helvetica.ttc) format("ttc");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "MYeun";
  src: local("MYeun"), url(./fonts/MYuen-Light-HKSCS-U.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "MYeun";
  src: local("MYeun"), url(./fonts/MYuen-Medium-HKSCS-U.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "MYeun";
  src: local("MYeun"), url(./fonts/MYuen-Xbold-HKSCS-U.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansCJKtc";
  src: local("NotoSansCJKtc"),
    url(./fonts/NotoSansCJKtc-Black.otf) format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansCJKtc";
  src: local("NotoSansCJKtc"),
    url(./fonts/NotoSansCJKtc-Bold.otf) format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansCJKtc";
  src: local("NotoSansCJKtc"),
    url(./fonts/NotoSansCJKtc-SemiLight.otf) format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansCJKtc";
  src: local("NotoSansCJKtc"),
    url(./fonts/NotoSansCJKtc-Light.otf) format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansCJKtc";
  src: local("NotoSansCJKtc"),
    url(./fonts/NotoSansCJKtc-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansCJKtc";
  src: local("NotoSansCJKtc"),
    url(./fonts/NotoSansCJKtc-Regular.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansCJKtc";
  src: local("NotoSansCJKtc"),
    url(./fonts/NotoSansCJKtc-Thin.otf) format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "黑繁";
  src: local("黑繁"), url(./fonts/黑繁.otf) format("opentype");
}
